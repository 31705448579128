import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './Events.css';
import Event from './Event';
import UpcomingEvent from './UpcomingEvent';
import {ourEvents, upcomingEvents} from './EventData';
import { getAllEvents } from '../../../service/event';

import AuthContext from '../../../context/AuthProvider';
import { IoIosAddCircleOutline } from "react-icons/io";
import toast from 'react-hot-toast';

export default function Events() {
    var currentTime = new Date();
    const years = [2024, 2023, 2022, 2021];
    const { auth } = useContext(AuthContext);
    const filteredEvents = ourEvents.filter(item => 
        item.startDate.getFullYear() === 2025 && item.startDate < currentTime);

    const [events, setEvents] = useState([]);

    useEffect(async () => {
        const respEvents = await getAllEvents();
        if (respEvents.state == "SUCCESS") {
            setEvents(respEvents.mess);
        } else {
            toast.error('Nem sikerült az események betöltése!');
        }
        console.log(respEvents);
    }, []);

    useEffect(() => {
        console.log(events);
    }, [events]);
    
    return (
        <>
            <h1>Események</h1>

            {/* // TODO: delete: #e24a35, edit: #338cb8, add: #3d9970 */}
            {
                (auth?.roleName === "admin") ?
                <Link to="uj">
                    <button className="submit">
                        <IoIosAddCircleOutline/> Új hozzáadása
                    </button> 
                </Link>: null
            }


            <h2>Közelgő események</h2>
            {
                upcomingEvents.length == 0 ? <p>Nincs megjelenítendő esemény.</p> :
                <div className='upcoming-events-items'>
                    {upcomingEvents}
                </div>
            }
            {/* <div className='upcoming-events-items'>
                {

                    ourEvents.filter(item => 
                        item.startDate.getTime() > currentTime.getTime()
                    ).map((obj) =>
                        <UpcomingEvent
                            key={obj.id}
                            id={obj.id}
                            img={obj.coverPhoto}
                            title={obj.title}
                            date={obj.startDate}
                            place={obj.place}
                            text={obj.description}
                            files={obj.files}
                            // style={{objectPosition: '0 50%'}}
                        />
                    )
                }
            </div> */}

            <h2>Korábbi események</h2>

            {/* <div className='event-container'>
                <h3 className="year">2024</h3>  
                {
                    events.map((obj) => 
                        <Event
                            key={obj.eventId}
                            id={obj.eventId}
                            // img={obj.coverPhoto}
                            title={obj.eventTitle}
                            date={new Date(obj.eventStart)}
                            place={`${obj.eventAdd} - ${obj.eventStreet}, ${obj.eventNr}, ${obj.eventCity}`}
                            text={obj.eventDesc}
                            // files={obj.files}
                        /> 
                    )
                }

            </div> */}

            <div className='event-container'>
                {
                    filteredEvents.length > 0 ?
                    <>
                        <h3 className="year">2025</h3>  
                        {
                            filteredEvents.map((obj) =>
                                <Event
                                    key={obj.id}
                                    id={obj.id}
                                    img={obj.coverPhoto}
                                    title={obj.title}
                                    date={obj.startDate}
                                    place={obj.place}
                                    text={obj.description}
                                    files={obj.files}
                                />
                            )
                        }
                    </> : null
                }
                {
                    years.map(year => {
                        return (
                        <React.Fragment key={year.toString()}>
                            <h3 className="year">{year}</h3>
                            {
                                ourEvents.filter(item => 
                                    item.startDate.getFullYear() == year.toString()
                                ).map((obj) =>
                                    <Event
                                        key={obj.id}
                                        id={obj.id}
                                        img={obj.coverPhoto}
                                        title={obj.title}
                                        date={obj.startDate}
                                        place={obj.place}
                                        text={obj.description}
                                        files={obj.files}
                                    />
                                )
                            }
                        </React.Fragment>
                        );
                    })
                }

            </div>

            {/* TODO: pagination + keresés + feltöltéskor compression */}
        </>
    );
}