import UpcomingEvent from "./UpcomingEvent";

var currentTime = new Date();

export const ourEvents = [
    {   id: 25,
        startDate: new Date('2025-01-26T11:00:00'),
        endDate: '',
        place: 'Nagyboldogasszony római katolikus templom - Str. Revoluției 5, Vajdahunyad',
        title: 'Magyar kultúra napja - Kórushangverseny Vajdahunyadon',
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2025/01/A3_Plakat_Vajdahunyad.pdf'}],
        coverPhoto: {img: 'img/events/2025/01/A3_Plakat_Vajdahunyad.jpg'}
    },
    {   id: 24,
        startDate: new Date('2025-01-25T16:00:00'),
        endDate: '',
        place: 'Törökvágási református templom - Donáth út 142, Kolozsvár',
        title: 'Magyar kultúra napja - Kórushangverseny Kolozsváron',
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2025/01/A3_Plakat_Kolozsvár.pdf'}],
    },
    {   id: 23,
        startDate: new Date('2024-12-14T15:00:00'),
        endDate: '',
        place: 'Szent Mihály-templom, Kolozsvár',
        title: 'Karácsonyváró hangverseny',
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2024/12/469627975_122174758148103295_2246386866592419963_n.jpg'}],
        coverPhoto: {img: 'img/events/2024/12/469627975_122174758148103295_2246386866592419963_n.jpg'},
        facebookPost: 'https://www.facebook.com/dalosszovetseg.ro/posts/pfbid0BU2nyVfqHyf2qm3vrrWszHpzXCVWrt39vW3R5Sx34CbqfWKyS294efGfCXdK9FUgl'
    },
    {   id: 22,
        startDate: new Date('2024-11-16T15:30:00'),
        endDate: '',
        place: 'BBTE Auditorium Maximum terem, Farkas utca 5, Kolozsvár',
        title: 'Erdélyi magyar zeneszerzők művei',
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2024/11/refteo_korushangv_nov16-01.png'}],
        coverPhoto: {img: 'img/events/2024/11/refteo_korushangv_nov16-01.png'},
        description: 'Ünnepi kórushangverseny'
    },
    {   id: 21,
        startDate: new Date('2024-05-24T20:00:00'),
        endDate: '',
        place: 'Kolozsvári unitárius templom - Str. Brassai Sámuel 6, Kolozsvár',
        title: 'Esti Kórushangverseny',
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2024/05/Korus-1-HU.pdf'}],
        coverPhoto: {img: 'img/events/2024/05/Korus-1-HU.jpg'},
        facebookPost: 'https://www.facebook.com/dalosszovetseg.ro/posts/122150295950103295'
    },
    {   id: 20,
        startDate: new Date('2024-05-11T15:00:00'),
        endDate: '',
        place: 'Székely Mikó Kollégium - Gróf Mikó Imre utca 1, Sepsiszentgyörgy',
        title: 'XIV. Éneklő ifjúság kórustalálkozó Kodály Zoltán szellemében',
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2024/05/A3_Plakat_Szentgyorgy_Kodaly-1.pdf'}],
        organizers: ['Romániai Magyar Dalosszövetség', 'Erdélyi Magyar Közművelődési Egyesület', 'Székely Mikó Kollégium'],
        coverPhoto: {img: 'img/events/2024/05/A3_Plakat_Szentgyorgy_Kodaly.jpg'},
        facebookPost: 'https://www.facebook.com/dalosszovetseg.ro/posts/pfbid023xj4bsmrcWuHBWjCKhjXECx8rh2f8ims1mCy7CJn6c9HCR8fGCcoRqLKLfLFQkbJl'
    },
    {   id: 19,
        startDate: new Date('2024-01-20T16:00:00'),
        endDate: '',
        place: 'Törökvágási református templom - Donáth út 142, Kolozsvár',
        title: 'Kórushangverseny a magyar kultúra napján – Kolozsvár',
        description: 'A részletes műsor <a href="https://www.emke.ro/magyar-kultura-napja-torda-es-kolozsvar-7190" target="_blank" rel="noreferrer">ide</a> kattintva érhető el.',
        organizers: ['Romániai Magyar Dalosszövetség', 'Erdélyi Magyar Közművelődési Egyesület', 'Kolozsvár-Törökvágási Református Egyházközség'],
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2024/A3_Plakat_K-var-1.jpg'}],
    },
    {   id: 18,
        startDate: new Date('2024-01-20T11:00:00'),
        endDate: '',
        place: 'Ótordai református templom - Str. Bogdan Petriceicu Haşdeu 2, Torda',
        title: 'Kórushangverseny a magyar kultúra napján – Torda',
        description: 'A részletes műsor <a href="https://www.emke.ro/magyar-kultura-napja-torda-es-kolozsvar-7190" target="_blank" rel="noreferrer">ide</a> kattintva érhető el.',
        organizers: ['Romániai Magyar Dalosszövetség', 'Erdélyi Magyar Közművelődési Egyesület', 'Ótordai Református Egyházközség',],
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2024/A3_Plakat_Torda-2.jpg'}],
        coverPhoto: {img: 'img/events/2024/A3_Plakat_Torda-2.jpg'},
    },
    {   id: 17,
        startDate: new Date('2023-12-16T15:00:00'),
        endDate: '',
        place: 'Báthory István Elméleti Líceum díszterme, Kogălniceanu / Farkas utca 2, Kolozsvár',
        title: 'Karácsonyváró hangverseny',
        organizers: ['Romániai Magyar Dalosszövetség', 'Erdélyi Magyar Közművelődési Egyesület'],
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2023/12/A3_Karacsonytvaro_2023-1.pdf'}],
    },
    {   
        id: 16,
        startDate: new Date('2023-11-11T15:30:00'),
        endDate: '',
        place: 'Kolozsvári Egyetemiek Háza - Auditorium Maximum terem, Farkas utca 5, Kolozsvár',
        title: 'Ünnepi kórushangverseny - In memoriam Guttman Mihály',
        organizers: ['Romániai Magyar Dalosszövetség', 'BBTE Református Tanárképző és Zeneművészeti Kar'],
        description: 'A 2023-as évi közgyűlés zárómozzanataként a Guttman Mihály nevet viselő kóruscsalád, ' +
        'majd a BBTE Református Tanárképző és Zeneművészeti Kar MusiChorum vegyeskarának ünnepélyes hangversenyére kerül sor.',
        files: [{fDescription: 'Az esemény plakátja', fLink: 'img/events/2023/11/Ünnepi kórushangverseny - In memoriam Guttman Mihály.pdf'}],
        coverPhoto: {img: 'img/events/2023/11/Ünnepi kórushangverseny - In memoriam Guttman Mihály_original.png'},
        links: [{lType: 'youtube', lLink: ['8sgYV3Wil4M', 'xQS5uKRpFhk', 'WrG1xiBHPiQ', 'f-LclHhmBYs', '8VUHpDN64vE', '0uG3TIPAUx0', 'LSDL8R7soHo']}]
    },
    {   
        id: 15,
        startDate: new Date('2023-11-11T09:30:00'),
        endDate: '',
        place: 'Kolozsvári Református Kollégium díszterme, Kogălniceanu / Farkas utca 16, Kolozsvár',
        title: 'A Romániai Magyar Dalosszövetség évi közgyűlése',
        description: 'A közgyűlésre szeretettel várjuk az együtteseink karmestereit és a vezetőségükből egy-egy képviselőt. ' + 
        'Kérjük a részvételi szándékukat/ebédigényüket eljuttatni legkésőbb 2023. november 3.-ig. További részletek a mellékelt dokumentumban vannak.',
        files: [{fDescription: 'Meghívó', fLink: 'img/events/2023/11/RMD-közgyülés-231111-meghivó.pdf'}],
    },
    {   
        id: 14,
        startDate: new Date('2023-10-14T10:00:00'),
        endDate: '',
        place: 'Református Egyházközség tanácsterme, Méra',
        title: 'XI. Jagamas János Népdaléneklési Verseny',
        description: '2023. október 14-én, szombaton 10.00 órakor Mérában (Kalotaszeg, Kolozs megye) a Református Egyházközség Tanácstermében népdaléneklési versenyt szervezünk 6-18 éves iskolások/fiatalok részére, immár 11.-ik alkalommal. ' +
        'Mérában emlékezünk a 110 éve született Jagamas Jánosra (1913-1997), a kiváló zenetudósra, zeneszerzőre, főiskolai tanárra, ott, ahol éveken át kórust vezetett, népdalt gyűjtött és a Művelődési Otthont róla nevezték el. ' +
        'Az érdeklődők jelentkezését 2023. október 6.-ig várjuk e-mailben a dalosszovetseg@yahoo.com címen, a mellékelt jelentkezési lap kitöltésével. ' + 
        'A jelentkezési ürlapon megadott 5 népdalból 1 népdalt a versenyző és 1 népdalt a zsüri választ. ' +
        'Jelentkezésüket várjuk!',
        files: [{fDescription: 'Felhívás', fLink: 'img/events/2023/10/Felhívás – JAGAMAS JÁNOS NÉPDALÉNEKLÉSI VERSENY – MÉRA – 2023.doc'}, 
        {fDescription: 'Jelentkezési lap', fLink: 'img/events/2023/10/Jelentkezesi-lap-Mera-2023.rtf'}],
    },
    {   
        id: 13,
        startDate: new Date('2023-05-20T15:00:00'),
        endDate: '',
        place: 'Dinu Lipatti Állami Filharmónia terme, Piața Libertății 8, Szatmárnémeti',
        title: 'XIII. Éneklő ifjúság Kodály Zoltán szellemében',
        description: '',
        files: [{fDescription: 'Az esemény plakátja', fLink: '/img/events/2023/05/A3_Plakat_Szatmar-2023.05.20.pdf'}],
        // coverPhoto: {img: 'img/events/2023/03/ÉNEKLŐBB ERDÉLY COVER V.jpg'},
    },
    {   
        id: 12,
        startDate: new Date('2023-03-27T09:00:00'),
        endDate: new Date('2023-03-30T20:00:00'),
        place: 'Kolozsvár, több helyszín',
        title: 'Éneklőbb Erdély - kórusműhely, karvezetéskurzus, éneklést népszerűsítő előadás',
        description: 'Székelyudvarhely, Nagybánya és Koltó után ezúttal Kolozsváron járnak az "Éneklőbb Erdély" karnagyai. ' +
        'Találkozzunk a Bánffy Miklós Operastúdióban tartott kóruséneklést népszerűsítő előadáson, a Babeș-Bolyai Tudományegyetem Református Tanárképző Zeneművészeti Karán tartott karvezetéskurzuson, illetve az iskolai énekkarok műhelymunkáin. ' +
        'A rendezvény előadói dr. Kovács Gábor és dr. Tóth Árpád, a Partiumi Keresztény Egyetem Zene Tanszékének oktatói. ' +
        'Köszönjük a befogadóintézmények közreműködését! ' +
        'Minden érdeklődőt szeretettel várunk! ' +
        '*az eseményeken való részvétel ingyenes, a műhelymunkákra előzetesen regisztrálni kell! ',
        files: [{fDescription: 'Az esemény plakátja', fLink: '/img/events/2023/03/ÉNEKLŐBB ERDÉLY COVER V.pdf'}],
        coverPhoto: {img: 'img/events/2023/03/ÉNEKLŐBB ERDÉLY COVER V.jpg'},
    },
    {
        id: 11,
        startDate: new Date('2023-03-27T18:00:00'),
        endDate: '',
        place: 'Bánffy Miklós Operastúdió, Rózsa (Samuil Micu) utca 12, Kolozsvár',
        title: 'A világ leghíresebb dallamának nyomában',
        description:
        'Dr. Tóth Árpád magyarországi zenetanár, karnagy, éneklést népszerűsítő világhírű egyetemi oktató remekbe ' + 
        'szabott előadásának témája a világ talán leghíresebb dallamának különleges útját ismerteti különböző ' +
        'stílustörténeti korokon és műfajokon keresztül. A minden korosztály számára izgalmas program különleges ' +
        'összefüggéseket tár fel különböző, akár ezer évvel ezelőtti zenei gyakorlatokról és rávilágít arra, ' +
        'hogy nem is változott olyan sokat zenei ízlésünk a történelem folyamán. ' +
        'Az előadás az Éneklőbb Erdély programsorozat keretén belül kerül megrendezésre. ',
        files: [{fDescription: 'Az esemény plakátja', fLink: '/img/events/2023/03/ÉNEKLŐBB ERDÉLY PDF.pdf'}],
        coverPhoto: {img: 'img/events/2023/03/ÉNEKLŐBB ERDÉLY PDF.jpg'},
    },
    {
        id: 10,
        startDate: new Date('2022-12-10T15:00:00'),
        endDate: '',
        place: 'Báthory István Elméleti Líceum díszterme, Kogălniceanu / Farkas utca 2, Kolozsvár',
        title: 'Karácsonyt váró kolozsvári iskolások énekkari hangversenye',
        files: [{fDescription: 'Az esemény plakátja', fLink: '/img/events/2022/12/A3_Karacsonyt_2022.pdf'}],
        links: [{lType: 'youtube', lLink: ['TU7hgDM4pkg', 'I5zAYrve9EU', '9K9dQiRgjwc', 'H_4JEZWozAw',
         '48KmbsmLui4', 'VvNoCqEQJbA', 'M45xk6tGYEk']}]
    },
    {
        id: 9,
        startDate: new Date('2022-11-26T18:00:00'),
        endDate: '',
        place: 'Protestáns Teológiai Intézet díszterme, Kolozsvár',
        title: '15 éves a Guttman Mihály Pedagóguskórus - Jubileumi hangverseny',
        description: '2007 októberében jött létre a Kolozsvári Magyar Pedagógusok Kamarakórusa, melynek megálmodója Bedő Ágnes zenetanár. ' +
        'Az énekkar összetétele változatos: tagjai tanítók, óvónők, reál- és humán- valamint zeneszakos pedagógusok. ' +
        'A kórus 2013 novemberében vette fel jelenlegi nevét, miután szeretett mesterük, Guttman Mihály eltávozott az élők sorából. ' +
        'A több mint 150 műből álló repertoárját Erdély városain kívül külföldön is többször megszólaltatta. ' +
        'Guttman Mihály kezdeményezésére a kórus 2012 óta az erdélyi szórványvidékekre is ellátogatott a Magyar Kultúra Napját ünnepelve. ' +
        '2015 áprilisában a KÓTA minősítő hangversenyén "Arany, dicsérettel" fokozatot ért el, majd 2018-ban "Fesztivál kórus" ' +
        'minősítést kapott. Nagy megtiszteltetés, hogy 2018 januárjában a kórus átvehette a 2017. évi KÓTA-díjat, ' +
        'amelyet a magyar zenekultúrában végzett kimagasló munkássága elismeréséül adományoztak az énekkarnak. ',
        links: [{lType: 'youtube', lLink: ['_i2X856f7Oo', '4-KiScv2PR4']}]
    },
    {
        id: 8,
        startDate: new Date('2022-11-12T15:00:00'),
        endDate: '',
        place: 'BBTE Auditorium Maximum terem, Farkas utca 5, Kolozsvár',
        title: 'In memoriam Kodály Zoltán',
        description: 'Ünnepi kórushangverseny. Műsoron a zeneszerző vegyeskari művei, gyermek- és nőikari művei, biciniumok. ',
        files: [{fDescription: 'Az esemény plakátja', fLink: '/img/events/2022/11/Kodaly-Zoltan-2022-11-12.png'}],
        links: [{lType: 'youtube', lLink: ['_DanCDzBS-o', 'Hf0lZz1k3G0', 'aRw2LfCgsNQ', 'Cd56PwJhQkY', 'DUBFCcLJ6CE', 'AA7tLDIG_I4']}]
    },
    {
        id: 7,
        startDate: new Date('2022-10-08T10:00:00'),
        endDate: '',
        place: 'Református Egyházközség tanácsterme, Méra',
        title: 'X. Jagamas János Népdaléneklési Verseny',
        description: 'A Romániai Magyar Dalosszövetség 2022. október 8-án, szombaton 10 órakor Mérában (Kalotaszeg, Kolozs megye) ' +
        'a Református Egyházközség tanácstermében népdaléneklési versenyt szervezett 6-18 éves iskolások/fiatalok részére, ' +
        'immár tizedik alkalommal. Mérában emlékezünk a 25 éve elhunyt Jagamas Jánosra (1913-1997), a kiváló zenetudósra, ' +
        'zeneszerzőre, főiskolai tanárra ott, ahol éveken át kórust vezetett, népdalt gyűjtött és a Művelődési Otthont róla nevezték el. ' +
        'A versenyre 25 fiatal jelentkezett. A résztvevőket Tóth-Guttman Emese, a RMD-elnöke és Mezei Csaba helyi lelkipásztor köszöntötte. ' +
        'A zsűri: Tóth-Guttman Emese karnagy, zenetanár, Bedő Ágnes karnagy, zenetanár és Mann Gabriella zenetanár. ' +
        'A versenyzők életkorát figyelembe véve a zsűri három kategóriában osztott díjat.',
        links: [{lType: 'youtube', lLink: ['fRp8QTSb_F4', 'BTYgWnCHLG4', 'Vx3EH-TSYSg']}]
    },
    {
        id: 6,
        startDate: new Date('2022-09-28T18:30:00'),
        endDate: '',
        place: 'Györkös Mányi Albert Emlékház, Rebublicii / Majális utca 5, Kolozsvár',
        title: 'Kerekasztal-beszélgetés Benedek Kálmán orgonaművész, karnagy, zenetanár születésének 110. évfordulóján',
        description: 'A Romániai Magyar Dalosszövetség kerekasztal beszélgetést szervezett  a 110 évvel ezelőtt született ' +
        'Benedek Kálmán karnagy-tanár, zongora-orgonaművész emlékére, 2022. szeptember 28-án, szerdán 18:30 órakor ' +
        'a Györkös Mányi Albert Emlékházban. ' +
        'A rendezvény házigazdája Tóth Guttman Emese, a Romániai Magyar Dalosszövetség elnöke. ' +
        'Meghívott vendégek: dr. Benkő Judit muzikológus, valamint Benkő Tímea, a Kolozsvári Protestáns Teológiai Intézet oktatója, karnagy.',
        organizers: ['Romániai Magyar Dalosszövetség', ' Györkös Mányi Albert Emlékház'],
        links: [{lType: 'youtube', lLink: ['DsLbMd_NYfA']}]
    },
    {   id: 5,
        startDate: new Date('2022-05-19T19:00:00'),
        endDate: '',
        place: 'Pietati evangélikus–lutheránus templom, 1989 December 21 / Magyar utca 1, Kolozsvár',
        title: '7. kolozsvári magyar egyetemista kórustalálkozó',
        organizers: ['Romániai Magyar Dalosszövetség', 'Visszhang Egyesület', 'Pietati'],
        files: [{fDescription: 'Az esemény plakátja', fLink: '/img/events/2022/05/7.Kolozsvári Magyar Egyetemista Kórustalálkozó.pdf'}],
        links: [{lType: 'youtube', lLink: ['srrAhNsnSOs', 'PeCmI9FJADQ', 'Qo4sB_E4xlE', '0mA5pLjN27Q', 'W40GMxAUUZY']}]
    },
    {
        id: 4,
        startDate: new Date('2022-05-14T15:30:00'),
        endDate: '',
        place: 'Kétágú templom, 1989 December 21 / Magyar utca 41, Kolozsvár',
        title: 'XX-XXI. századi magyar zeneszerzők művei',
        description: 'Ünnepi kórushangverseny a Romániai Magyar Dalosszövetség fennállásának 100. évfordulójára rendezett programsorozat részeként. ',
        files: [{fDescription: 'Az esemény plakátja', fLink: '/img/events/2022/05/A3-Plakat-100eves.pdf'}]
    },
    {
        id: 3,
        startDate: new Date('2022-05-14T15:30:00'),
        endDate: '',
        place: 'Kétágú templom, 1989 December 21 / Magyar utca 41, Kolozsvár',
        title: 'Emlékkiállítás',
        description: 'Roll-up kiállítás a Romániai Magyar Dalosszövetség 100 éves múltjából. ',
        files: [{fDescription: 'Tárgyi-, és írásos emlékek, együttesek és énekkarok (részlet)', fLink: '/img/events/2022/rollup/Rollup.pdf'}]
    },
    {
        id: 2,
        startDate: new Date('2022-05-13'),
        endDate: new Date('2022-05-14'),
        place: 'Kolozsvár, több helyszín',
        title: '100 éves a Romániai Magyar Dalosszövetség – II. felvonás',
        description: 'Az ünnepi rendezvénysorozat második része. ',
        files: [{fDescription: 'Programfüzet', fLink: '/img/events/2022/05/RMD-100 musor 2022 május 13-14.pdf'}],
        links: [{lType: 'youtube', lLink: ['AQtlwsMoRr0', 'ZGoY5WE5yd4', 'B6PJguvLLHM', 'xo9iOnOjT4I',
         '57AWIrtyODw', 'KeWpjh13mEg', '13sHRu5CQz8', 'wHbInVm31Nw']
        }]
    },
    {
        id: 1,
        startDate: new Date('2021-11-13'),
        endDate: '',
        place: 'Kolozsvár, több helyszín',
        title: '100 éves a Romániai Magyar Dalosszövetség',
        description: 'Az ünnepi rendezvénysorozat első része. ',
        files: [{fDescription: 'Programfüzet', fLink: '/img/events/2021/11/Fuzet_100-2.pdf'}],
        links: [{lType: 'youtube', lLink: ['qd670DJ1rCY']}]
    },
];

export const upcomingEvents = 
    ourEvents.filter(item => 
        item.startDate.getTime() > currentTime.getTime()
    ).map((obj) =>
        <UpcomingEvent
            key={obj.id}
            id={obj.id}
            img={obj.coverPhoto}
            title={obj.title}
            date={obj.startDate}
            place={obj.place}
            text={obj.description}
            files={obj.files}
            // style={{objectPosition: '0 50%'}}
        />
    );
    
// TODO: add sorting on startdate
export const eventsWithCover = 
    ourEvents.filter(item => 
        item.coverPhoto != null
    ).map(obj => (
        {
            id: obj.id, 
            coverPhoto: obj.coverPhoto
        }
    )).slice(0,4);